import { defineComponent, ref } from 'vue';
import AchievementDifficultyFilter from '@/components/achievements/achievement-filter/achievement-difficulty-filter/AchievementDifficultyFilter.vue';
import NicknameToggler from '@/components/core/filters/nickname-toggler/NicknameToggler.vue';
import TextFilter from '@/components/core/filters/text-filter/TextFilter.vue';
import FilterAside from '@/components/layout/asides/filter-aside/FilterAside.vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    name: 'AchievementFilters',
    components: {
        CTAButton,
        FilterAside,
        AchievementDifficultyFilter,
        NicknameToggler,
        TextFilter
    },
    emits: [
        'filter'
    ],
    setup(props, context) {
        const achievementContent = ref();
        const profileIdFilter = ref();
        const nicknameFilter = ref();
        const difficultyFilter = ref();
        const isFilteredByPlayer = ref(false);
        const filterByPlayer = (isFilter) => isFilteredByPlayer.value = isFilter;
        function filter() {
            var _a, _b;
            const achievementFilter = {
                description: ((_a = achievementContent === null || achievementContent === void 0 ? void 0 : achievementContent.value) === null || _a === void 0 ? void 0 : _a.inputData) ? achievementContent.value.inputData : null,
                profile_id: ((_b = profileIdFilter === null || profileIdFilter === void 0 ? void 0 : profileIdFilter.value) === null || _b === void 0 ? void 0 : _b.inputData) ? profileIdFilter.value.inputData : null,
                difficulty: difficultyFilter === null || difficultyFilter === void 0 ? void 0 : difficultyFilter.value.getDifficulty(),
                nickname: (nicknameFilter === null || nicknameFilter === void 0 ? void 0 : nicknameFilter.value) ? nicknameFilter.value.inputData : null
            };
            context.emit('filter', achievementFilter);
        }
        return {
            isFilteredByPlayer,
            achievementContent,
            difficultyFilter,
            nicknameFilter,
            profileIdFilter,
            filterByPlayer,
            filter
        };
    }
});
