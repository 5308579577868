import { defineComponent, ref } from 'vue';
import { ACHIEVEMENT_TYPE_LABEL_HARD, ACHIEVEMENT_TYPE_LABEL_LABORIOUS, ACHIEVEMENT_TYPE_LABEL_MAIN, ACHIEVEMENT_TYPE_LABEL_NORMAL, ACHIEVEMENT_TYPE_LABEL_STARTING, reTranslateAchievementType } from '@/assets/ts/helpers/achievement';
const FILTER_BUTTONS = [
    { id: 1, name: ACHIEVEMENT_TYPE_LABEL_STARTING, class: 'starting-btn' },
    { id: 2, name: ACHIEVEMENT_TYPE_LABEL_MAIN, class: 'main-btn' },
    { id: 3, name: ACHIEVEMENT_TYPE_LABEL_NORMAL, class: 'normal-btn' },
    { id: 4, name: ACHIEVEMENT_TYPE_LABEL_HARD, class: 'hard-btn' },
    { id: 5, name: ACHIEVEMENT_TYPE_LABEL_LABORIOUS, class: 'laborious-btn' }
];
const FILTER_BUTTON_INACTIVE_CLASS = 'inactive';
export default defineComponent({
    name: 'AchievementDifficultyFilter',
    setup() {
        const activeFilter = ref([]);
        function activateFilter(filterButton) {
            const found = activeFilter.value.find(active => active.id === filterButton.id);
            if (found) {
                activeFilter.value = activeFilter.value.filter(active => active.id !== filterButton.id);
            }
            else {
                activeFilter.value.push(filterButton);
            }
        }
        function getFilterButtonClasses(filterButton) {
            let classObject = { inactive: false };
            const isActive = activeFilter.value.find(active => active.id === filterButton.id);
            classObject[FILTER_BUTTON_INACTIVE_CLASS] = !isActive;
            classObject[filterButton.class] = true;
            return classObject;
        }
        function getDifficulty() {
            return activeFilter.value.map(active => reTranslateAchievementType(active === null || active === void 0 ? void 0 : active.name));
        }
        return { activeFilter, activateFilter, FILTER_BUTTONS, getDifficulty, getFilterButtonClasses };
    }
});
