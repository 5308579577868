import { defineComponent, ref } from 'vue';
import Subpage from '@/components/layout/subpage/Subpage.vue';
import AchievementFilters from '@/components/achievements/achievement-filter/AchievementFilters.vue';
import AchievementList from '@/components/achievements/achievement-list/AchievementList.vue';
export default defineComponent({
    components: {
        AchievementFilters,
        AchievementList,
        Subpage
    },
    setup() {
        const achievementList = ref();
        function onFilter(event) {
            achievementList.value.filterAchievements(event);
        }
        return { achievementList, onFilter };
    }
});
