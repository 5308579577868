import { defineComponent } from 'vue';
import { translateAchievementType } from '@/assets/ts/helpers/achievement';
export default defineComponent({
    name: 'AchievementCard',
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        done: {
            type: Boolean,
            required: false,
            default: false
        },
        stateExists: {
            type: Boolean,
            required: false,
            default: false
        },
        achievementType: {
            type: Object,
            required: false,
            default: null
        }
    },
    setup(props) {
        function getTitle() {
            return translateAchievementType(props.title);
        }
        return { getTitle };
    }
});
