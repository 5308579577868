import { defineComponent, onMounted, ref } from 'vue';
import { getAchievements, getFilteredAchievements } from '@/assets/ts/api/achievement';
import AchievementCard from '@/components/achievements/achievement-list/achievement-card/AchievementCard.vue';
import RightAside from '@/components/layout/asides/right-aside/RightAside.vue';
export default defineComponent({
    name: 'AchievementList',
    components: {
        AchievementCard,
        RightAside
    },
    setup() {
        const achievementList = ref([]);
        const isFilterByPlayer = ref(false);
        const assignedProfileNote = ref('');
        const isFiltered = ref(false);
        onMounted(() => {
            getAchievements().then((results) => achievementList.value = results.achievements).catch((err) => {
                console.error(err);
            });
        });
        function filterAchievements(filter) {
            const filterData = filter;
            isFiltered.value = false;
            getFilteredAchievements(filterData).then((results) => {
                achievementList.value = results.achievements;
                isFilterByPlayer.value = filterData && (Boolean(filterData.profile_id) || Boolean(filterData.nickname));
                let nicknameNote = '';
                if (results === null || results === void 0 ? void 0 : results.nickname) {
                    nicknameNote += ` o nicku: ${results.nickname} ` + ((results === null || results === void 0 ? void 0 : results.profile_id) ? `(${results.profile_id})` : '');
                    isFiltered.value = true;
                }
                else if (results === null || results === void 0 ? void 0 : results.profile_id) {
                    nicknameNote += ` o ID: ${results.profile_id}`;
                    isFiltered.value = true;
                }
                assignedProfileNote.value = nicknameNote;
            }).catch((err) => console.error(`getFilteredAchievements(${filterData}) err`, err));
        }
        return { achievementList, assignedProfileNote, isFiltered, isFilterByPlayer, filterAchievements };
    }
});
